import { User } from './api-types';

const localStorageKey = '__regaberCP_token__';
const SSO_HOST = 'https://regaber.com';
const NO_SSO_HOST = 'https://portalcliente.regaber.com';

async function client(
  endpoint: string,
  data?: any,
  { headers: customHeaders, ...customConfig }: RequestInit = {},
) {
  const user = getToken();

  const config: RequestInit = {
    method: data ? 'POST' : 'GET',
    body: data
      ? data instanceof FormData
        ? data
        : JSON.stringify(data)
      : undefined,
    headers: {
      'X-Auth-Token': user ? `${user.token}` : '',
      ...customHeaders,
    },
    ...customConfig,
  };

  // SSO
  let fixedApiEndpoint;
  if (window.location.origin === SSO_HOST) {
    fixedApiEndpoint = `${window.location.origin}/portalcliente/api/v1/`;
  } else {
    fixedApiEndpoint = `${window.location.origin}/api/v1/`;
  }

  return window
    .fetch(`${fixedApiEndpoint}${endpoint}`, config)
    .then(async response => {
      if (response.status === 401) {
        logout();

        // refresh the page for them

        // For SSO login redirect to regular login
        window.location.origin === SSO_HOST
          ? window.location.assign(`${NO_SSO_HOST}/?logout=true`)
          : window.history.pushState(
              {},
              document.title,
              window.location.origin,
            );
        // window.location.assign(window.location.toString());

        // window.location.assign(window.location.toString());

        return Promise.reject({ message: 'Please re-authenticate.' });
      }
      const data = await response.json();

      if (response.ok) {
        return data;
      }

      return Promise.reject(data);
    });
}

async function clientFile(
  endpoint: string,
  data?: any,
  { headers: customHeaders, ...customConfig }: RequestInit = {},
) {
  const user = getToken();

  const config: RequestInit = {
    method: data ? 'POST' : 'GET',
    body: data
      ? data instanceof FormData
        ? data
        : JSON.stringify(data)
      : undefined,
    headers: {
      'X-Auth-Token': user ? `${user.token}` : '',
      ...customHeaders,
    },
    ...customConfig,
  };

  // SSO
  let fixedApiEndpoint;
  if (window.location.origin === SSO_HOST) {
    fixedApiEndpoint = `${window.location.origin}/portalcliente/api/v1/`;
  } else {
    fixedApiEndpoint = `${window.location.origin}/api/v1/`;
  }

  return window
    .fetch(`${fixedApiEndpoint}${endpoint}`, config)
    .then(async response => {
      if (response.status === 401) {
        logout();
        // refresh the page for them

        // For SSO login redirect to regular login
        window.location.origin === SSO_HOST
          ? window.location.assign(`${NO_SSO_HOST}/?logout=true`)
          : window.history.pushState(
              {},
              document.title,
              window.location.origin,
            );

        return Promise.reject({ message: 'Please re-authenticate.' });
      }
      const blob = await response.blob();

      const contentDisposition = await response.headers.get(
        'content-disposition',
      );

      if (response.ok) {
        const contentDispositionSplit = contentDisposition?.split(';');
        const filename =
          contentDispositionSplit && contentDispositionSplit[1].split('=');
        const filenameValue = filename && filename[1];

        return { content: blob, filename: filenameValue };
      }

      return Promise.reject(blob);
    });
}

function logout() {
  window.localStorage.removeItem(localStorageKey);
}

function getToken(): User | null {
  const user = window.localStorage.getItem(localStorageKey);

  if (!user) {
    return null;
  }

  try {
    return JSON.parse(user);
  } catch {
    return null;
  }
}

function getNonce(): string | null {
  const urlParams = window.location.search.slice(1).split('&')[0].split('=');
  const nonce = urlParams[0] === 'WP-Nonce' ? urlParams[1] : '';

  if (!nonce) {
    return null;
  }

  return nonce;
}

function checkLogoutParam() {
  const urlParams = window.location.search.slice(1).split('&')[0].split('=');
  const logoutParam = urlParams[0] === 'logout' ? urlParams[1] : '';

  if (logoutParam) {
    logout();
    window.history.pushState({}, document.title, window.location.origin);
  }
}

export {
  client,
  clientFile,
  localStorageKey,
  logout,
  getToken,
  getNonce,
  checkLogoutParam,
};
