import * as auth from './auth-client';
import { User, MasterDataCollection, MasterDataItem } from './api-types';
import { client, getNonce, checkLogoutParam } from './api-client';
import { setUserInfoGa } from '../utils/react-ga';
async function bootstrapAppData(currrentLanguage = 'ES') {
  let appData: {
    user: User;
    masterCollections: MasterDataCollection[];
    masterCollectionsMaterial: MasterDataCollection[];
    masterCollectionsCustomer: MasterDataCollection[];
    selectedCustomer: MasterDataItem;
  } = {
    user: {},
    masterCollections: [],
    masterCollectionsMaterial: [],
    masterCollectionsCustomer: [],
    selectedCustomer: {},
  };

  //Se verifica si viene el parametro para hacer logout
  checkLogoutParam();

  //Si el usuario no está logueado y viene el nonce se intenta hacer login por SSO
  if (!auth.isLoggedIn() && getNonce()) {
    await auth.loginSSO();
  }

  if (auth.isLoggedIn()) {
    const [
      user,
      masterCollections,
      masterCollectionsMaterial,
      masterCollectionsCustomer,
    ] = await Promise.all([
      auth.getUser(),
      client(`master/collections?lan=${currrentLanguage}`),
      client(`master/collections/material?lan=${currrentLanguage}`),
      client(`master/collections/customer?lan=${currrentLanguage}`),
    ]);

    setUserInfoGa(user);

    appData = {
      user: user,
      masterCollections,
      masterCollectionsMaterial,
      masterCollectionsCustomer,
      selectedCustomer: {},
    };

    if (
      masterCollectionsCustomer.items &&
      masterCollectionsCustomer.items.length > 0
    ) {
      appData.selectedCustomer = masterCollectionsCustomer.items[0];
    }
  }

  return appData;
}

export { bootstrapAppData };
