import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import commonEn from './translations/en/translation.json';
import commonEs from './translations/es/translation.json';
import commonPt from './translations/pt/translation.json';
import commonFr from './translations/fr/translation.json';

const resources = {
  es: {
    translation: commonEs,
  },
  en: {
    translation: commonEn,
  },
  pt: {
    translation: commonPt,
  },
  fr: {
    translation: commonFr,
  },
};

const options = {
  order: ['navigator'],
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    /* backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'  
    }, */
    fallbackLng: 'es',
    resources,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: options,
    react: {
      wait: true,
    },
  });

export default i18n;
