import React from 'react';

import { bootstrapAppData } from '../utils/bootstrap';
import * as authClient from '../utils/auth-client';
import { useAsync } from '../utils/use-async';
import { FullPageSpinner } from '../components/full-page-spinner';
import { FullPageErrorFallback } from '../components/full-page-error-fallback';
import { User, MasterDataCollection, MasterDataItem } from '../utils/api-types';
import { client } from './../utils/api-client';
import { useTranslation } from 'react-i18next';

interface IAuthContext {
  user: User;
  [key: string]: any;
}

const AuthContext = React.createContext<IAuthContext>({
  user: {},
});
AuthContext.displayName = 'AuthContext';

function AuthProvider(props) {
  const {
    data,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync<{
    user: User;
    masterCollections: MasterDataCollection[];
    masterCollectionsMaterial: MasterDataCollection[];
    masterCollectionsCustomer: MasterDataCollection[];
    selectedCustomer: MasterDataItem;
  }>();

  const { i18n, ready } = useTranslation('', { useSuspense: false });

  React.useLayoutEffect(() => {
    if (ready) {
      const currrentLanguage = i18n.language.toUpperCase();
      run(bootstrapAppData(currrentLanguage));
    }
  }, [run, ready, i18n]);

  // React.useLayoutEffect(() => {
  //   setTimeout(() => {}, 10000);
  // }, []);

  const login = React.useCallback(
    form =>
      authClient.login(form).then(() => {
        const currrentLanguage = i18n.language.toUpperCase();
        run(bootstrapAppData(currrentLanguage));
      }),
    [run, ready, i18n],
  );

  const logout = React.useCallback(() => {
    authClient.logout();
    const labelCustomerLocal = window.localStorage.getItem('lastCustomer');
    labelCustomerLocal && window.localStorage.removeItem('lastCustomer');
    setData(null);
  }, [setData]);

  const user = data?.user;
  const masterCollections = data?.masterCollections;
  const masterCollectionsCustomer = data?.masterCollectionsCustomer;

  //Al actualizar el listado de materiales se re-renderiza toda la aplicacion por eso se guardan en el localstorage
  window.localStorage.setItem(
    'materialCollection',
    JSON.stringify(data?.masterCollectionsMaterial),
  );
  // const masterCollectionsMaterial = data?.masterCollectionsMaterial;

  const reloadMaterials = React.useCallback(() => {
    const currrentLanguage = i18n.language.toUpperCase();
    client(`master/collections/material?lan=${currrentLanguage}`).then(res => {
      window.localStorage.setItem('materialCollection', JSON.stringify(res));
    });
  }, []);

  const initialCustomer: MasterDataItem = {};

  const [selectedCustomer, setSelectedCustomer] = React.useState(
    initialCustomer,
  );

  React.useEffect(() => {
    const lastCustomerLocal = window.localStorage.getItem('lastCustomer');
    if (lastCustomerLocal) {
      const lastCustomer = JSON.parse(lastCustomerLocal);
      setSelectedCustomer(lastCustomer);
    } else if (data?.selectedCustomer) {
      setSelectedCustomer(data?.selectedCustomer);
    }
  }, [data]);

  const value = React.useMemo(
    () => ({
      login,
      logout,
      user,
      masterCollections,
      // masterCollectionsMaterial,
      masterCollectionsCustomer,
      selectedCustomer,
      setSelectedCustomer,
      reloadMaterials,
    }),
    [
      login,
      logout,
      user,
      masterCollections,
      // masterCollectionsMaterial,
      masterCollectionsCustomer,
      selectedCustomer,
      setSelectedCustomer,
      reloadMaterials,
    ],
  );

  if (isLoading || isIdle) {
    return <FullPageSpinner />;
  }

  if (isError && error) {
    return <FullPageErrorFallback error={error} />;
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
