import { client, localStorageKey, getToken, getNonce } from './api-client';
import { User } from './api-types';

function getUser(): Promise<User> {
  const token = getToken();

  if (token) {
    return client(`users/data`);
  }

  return Promise.reject(Error('User not logged in!'));
}

async function login({ username, password }): Promise<User> {
  const formData = new FormData();

  formData.append('userId', username);
  formData.append('password', password);

  return client(`users/auth`, formData).then((data: User) => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(data));
    window.localStorage.removeItem('lastCustomer');
    return Promise.resolve(data);
  });
}

async function loginSSO() {
  const formData = new FormData();
  const nonce = getNonce() ?? '';
  formData.append('nonce', nonce);
  await client('users/sso', formData).then((data: User) => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(data));
  });
}

function isLoggedIn(): boolean {
  return Boolean(getToken());
}

export { login, loginSSO, getUser, isLoggedIn };
export { logout, getToken } from './api-client';
