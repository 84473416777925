import React from 'react';

interface FullPageErrorFallbackProps {
  error: Error;
}

function FullPageErrorFallback({ error }: FullPageErrorFallbackProps) {
  return (
    <div className="h-screen text-red-600 flex flex-col items-center justify-center">
      <p>Uh oh... There's a problem. Try refreshing the app.</p>
      <pre>{error.message}</pre>
    </div>
  );
}

export { FullPageErrorFallback };
