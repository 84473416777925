import React from 'react';

import { Spinner } from './ui/spinner';

function FullPageSpinner() {
  return (
    <div className="h-screen text-6xl flex flex-col items-center justify-center">
      <Spinner />
    </div>
  );
}

export { FullPageSpinner };
