// import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';
import jwt_decode from 'jwt-decode';
import { User, TokenDecoded } from './api-types';
import { getToken } from './api-client';

console.log(process.env.REACT_APP_GA_KEY);
const trackingId = process.env.REACT_APP_GA_KEY ?? 'G-5NWNN4R6KG';

// export const eventGa = (category, action) => {
//   ReactGA.event({
//     category: category,
//     action: action,
//   });
// };

export const initGa = () => {
  ReactGA.initialize([{ trackingId: trackingId }]);
  // ReactGA.initialize(trackingId);
};
export const pageViewGa = page => {
  // ReactGA.pageview(page);
  ReactGA.send({ hitType: 'pageview', page: page, title: page });
};

export const setUserInfoGa = user => {
  initGa();
  const userToken = getToken();

  if (userToken && userToken.token) {
    const tokenDecoded: TokenDecoded = jwt_decode(userToken.token);

    // ReactGA.set({
    //   dimension1:
    //     user.app_role === 'CUSTOMER' ? tokenDecoded.customerInView : '', // Si no es cliente no guardamos el id
    //   dimension2: user.app_role, //Rol
    //   dimension3: user.user_province, // Provincia
    //   dimension4: user.user_country, // Pais
    //   // any data that is relevant to the user session
    //   // that you would like to track with google analytics
    // });

    ReactGA.event(
      {
        category: 'user_properties',
        action: 'set',
      },
      {
        dimension1:
          user.app_role === 'CUSTOMER' ? tokenDecoded.customerInView : '',
        dimension2: user.app_role, //Rol
        dimension3: user.user_province, // Provincia
        dimension4: user.user_country, // Pais
      },
    );
  }
};
